
    <template>
      <section class="content element-doc">
        <h2>Container 布局容器</h2>
<p>用于布局的容器组件，方便快速搭建页面的基本结构：</p>
<p><code>&lt;el-container&gt;</code>：外层容器。当子元素中包含 <code>&lt;el-header&gt;</code> 或 <code>&lt;el-footer&gt;</code> 时，全部子元素会垂直上下排列，否则会水平左右排列。</p>
<p><code>&lt;el-header&gt;</code>：顶栏容器。</p>
<p><code>&lt;el-aside&gt;</code>：侧边栏容器。</p>
<p><code>&lt;el-main&gt;</code>：主要区域容器。</p>
<p><code>&lt;el-footer&gt;</code>：底栏容器。</p>
<div class="tip">
<p>以上组件采用了 flex 布局，使用前请确定目标浏览器是否兼容。此外，<code>&lt;el-container&gt;</code> 的子元素只能是后四者，后四者的父元素也只能是 <code>&lt;el-container&gt;</code>。</p>
</div>
<h3>常见页面布局</h3>
<demo-block>
        
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-container&gt;
  &lt;el-header&gt;Header&lt;/el-header&gt;
  &lt;el-main&gt;Main&lt;/el-main&gt;
&lt;/el-container&gt;

&lt;el-container&gt;
  &lt;el-header&gt;Header&lt;/el-header&gt;
  &lt;el-main&gt;Main&lt;/el-main&gt;
  &lt;el-footer&gt;Footer&lt;/el-footer&gt;
&lt;/el-container&gt;

&lt;el-container&gt;
  &lt;el-aside width=&quot;200px&quot;&gt;Aside&lt;/el-aside&gt;
  &lt;el-main&gt;Main&lt;/el-main&gt;
&lt;/el-container&gt;

&lt;el-container&gt;
  &lt;el-header&gt;Header&lt;/el-header&gt;
  &lt;el-container&gt;
    &lt;el-aside width=&quot;200px&quot;&gt;Aside&lt;/el-aside&gt;
    &lt;el-main&gt;Main&lt;/el-main&gt;
  &lt;/el-container&gt;
&lt;/el-container&gt;

&lt;el-container&gt;
  &lt;el-header&gt;Header&lt;/el-header&gt;
  &lt;el-container&gt;
    &lt;el-aside width=&quot;200px&quot;&gt;Aside&lt;/el-aside&gt;
    &lt;el-container&gt;
      &lt;el-main&gt;Main&lt;/el-main&gt;
      &lt;el-footer&gt;Footer&lt;/el-footer&gt;
    &lt;/el-container&gt;
  &lt;/el-container&gt;
&lt;/el-container&gt;

&lt;el-container&gt;
  &lt;el-aside width=&quot;200px&quot;&gt;Aside&lt;/el-aside&gt;
  &lt;el-container&gt;
    &lt;el-header&gt;Header&lt;/el-header&gt;
    &lt;el-main&gt;Main&lt;/el-main&gt;
  &lt;/el-container&gt;
&lt;/el-container&gt;

&lt;el-container&gt;
  &lt;el-aside width=&quot;200px&quot;&gt;Aside&lt;/el-aside&gt;
  &lt;el-container&gt;
    &lt;el-header&gt;Header&lt;/el-header&gt;
    &lt;el-main&gt;Main&lt;/el-main&gt;
    &lt;el-footer&gt;Footer&lt;/el-footer&gt;
  &lt;/el-container&gt;
&lt;/el-container&gt;

&lt;style&gt;
  .el-header,
  .el-footer {
    background-color: #b3c0d1;
    color: #333;
    text-align: center;
    line-height: 60px;
  }

  .el-aside {
    background-color: #d3dce6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }

  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    line-height: 160px;
  }

  body &gt; .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>实例</h3>
<demo-block>
        
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-container style=&quot;height: 500px; border: 1px solid #eee&quot;&gt;
  &lt;el-aside width=&quot;250px&quot; style=&quot;background-color: rgb(238, 241, 246)&quot;&gt;
    &lt;el-menu :default-openeds=&quot;['1', '3']&quot;&gt;
      &lt;el-submenu index=&quot;1&quot;&gt;
        &lt;template v-slot:title&gt;&lt;i class=&quot;el-icon-message&quot;&gt;&lt;/i&gt;导航一&lt;/template&gt;
        &lt;el-menu-item-group&gt;
          &lt;template v-slot:title&gt;分组一&lt;/template&gt;
          &lt;el-menu-item index=&quot;1-1&quot;&gt;选项1&lt;/el-menu-item&gt;
          &lt;el-menu-item index=&quot;1-2&quot;&gt;选项2&lt;/el-menu-item&gt;
        &lt;/el-menu-item-group&gt;
        &lt;el-menu-item-group title=&quot;分组2&quot;&gt;
          &lt;el-menu-item index=&quot;1-3&quot;&gt;选项3&lt;/el-menu-item&gt;
        &lt;/el-menu-item-group&gt;
        &lt;el-submenu index=&quot;1-4&quot;&gt;
          &lt;template v-slot:title&gt;选项4&lt;/template&gt;
          &lt;el-menu-item index=&quot;1-4-1&quot;&gt;选项4-1&lt;/el-menu-item&gt;
        &lt;/el-submenu&gt;
      &lt;/el-submenu&gt;
      &lt;el-submenu index=&quot;2&quot;&gt;
        &lt;template v-slot:title&gt;&lt;i class=&quot;el-icon-menu&quot;&gt;&lt;/i&gt;导航二&lt;/template&gt;
        &lt;el-menu-item-group&gt;
          &lt;template v-slot:title&gt;分组一&lt;/template&gt;
          &lt;el-menu-item index=&quot;2-1&quot;&gt;选项1&lt;/el-menu-item&gt;
          &lt;el-menu-item index=&quot;2-2&quot;&gt;选项2&lt;/el-menu-item&gt;
        &lt;/el-menu-item-group&gt;
        &lt;el-menu-item-group title=&quot;分组2&quot;&gt;
          &lt;el-menu-item index=&quot;2-3&quot;&gt;选项3&lt;/el-menu-item&gt;
        &lt;/el-menu-item-group&gt;
        &lt;el-submenu index=&quot;2-4&quot;&gt;
          &lt;template v-slot:title&gt;选项4&lt;/template&gt;
          &lt;el-menu-item index=&quot;2-4-1&quot;&gt;选项4-1&lt;/el-menu-item&gt;
        &lt;/el-submenu&gt;
      &lt;/el-submenu&gt;
      &lt;el-submenu index=&quot;3&quot;&gt;
        &lt;template v-slot:title&gt;&lt;i class=&quot;el-icon-setting&quot;&gt;&lt;/i&gt;导航三&lt;/template&gt;
        &lt;el-menu-item-group&gt;
          &lt;template v-slot:title&gt;分组一&lt;/template&gt;
          &lt;el-menu-item index=&quot;3-1&quot;&gt;选项1&lt;/el-menu-item&gt;
          &lt;el-menu-item index=&quot;3-2&quot;&gt;选项2&lt;/el-menu-item&gt;
        &lt;/el-menu-item-group&gt;
        &lt;el-menu-item-group title=&quot;分组2&quot;&gt;
          &lt;el-menu-item index=&quot;3-3&quot;&gt;选项3&lt;/el-menu-item&gt;
        &lt;/el-menu-item-group&gt;
        &lt;el-submenu index=&quot;3-4&quot;&gt;
          &lt;template v-slot:title&gt;选项4&lt;/template&gt;
          &lt;el-menu-item index=&quot;3-4-1&quot;&gt;选项4-1&lt;/el-menu-item&gt;
        &lt;/el-submenu&gt;
      &lt;/el-submenu&gt;
    &lt;/el-menu&gt;
  &lt;/el-aside&gt;

  &lt;el-container&gt;
    &lt;el-header style=&quot;text-align: right; font-size: 12px&quot;&gt;
      &lt;el-dropdown&gt;
        &lt;i class=&quot;el-icon-setting&quot; style=&quot;margin-right: 15px&quot;&gt;&lt;/i&gt;
        &lt;template #dropdown&gt;
          &lt;el-dropdown-menu&gt;
            &lt;el-dropdown-item&gt;查看&lt;/el-dropdown-item&gt;
            &lt;el-dropdown-item&gt;新增&lt;/el-dropdown-item&gt;
            &lt;el-dropdown-item&gt;删除&lt;/el-dropdown-item&gt;
          &lt;/el-dropdown-menu&gt;
        &lt;/template&gt;
      &lt;/el-dropdown&gt;
      &lt;span&gt;王小虎&lt;/span&gt;
    &lt;/el-header&gt;

    &lt;el-main&gt;
      &lt;el-table :data=&quot;tableData&quot;&gt;
        &lt;el-table-column prop=&quot;date&quot; label=&quot;日期&quot; width=&quot;140&quot;&gt;
        &lt;/el-table-column&gt;
        &lt;el-table-column prop=&quot;name&quot; label=&quot;姓名&quot; width=&quot;120&quot;&gt;
        &lt;/el-table-column&gt;
        &lt;el-table-column prop=&quot;address&quot; label=&quot;地址&quot;&gt; &lt;/el-table-column&gt;
      &lt;/el-table&gt;
    &lt;/el-main&gt;
  &lt;/el-container&gt;
&lt;/el-container&gt;

&lt;style&gt;
  .el-header {
    background-color: #b3c0d1;
    color: #333;
    line-height: 60px;
  }

  .el-aside {
    color: #333;
  }
&lt;/style&gt;

&lt;script&gt;
  export default {
    data() {
      const item = {
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }
      return {
        tableData: Array(20).fill(item)
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Container Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>direction</td>
<td>子元素的排列方向</td>
<td>string</td>
<td>horizontal / vertical</td>
<td>子元素中有 <code>el-header</code> 或 <code>el-footer</code> 时为 vertical，否则为 horizontal</td>
</tr>
</tbody>
</table>
<h3>Header Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>height</td>
<td>顶栏高度</td>
<td>string</td>
<td>—</td>
<td>60px</td>
</tr>
</tbody>
</table>
<h3>Aside Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>width</td>
<td>侧边栏宽度</td>
<td>string</td>
<td>—</td>
<td>300px</td>
</tr>
</tbody>
</table>
<h3>Footer Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>height</td>
<td>底栏高度</td>
<td>string</td>
<td>—</td>
<td>60px</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_header = _resolveComponent("el-header")
  const _component_el_main = _resolveComponent("el-main")
  const _component_el_container = _resolveComponent("el-container")
  const _component_el_footer = _resolveComponent("el-footer")
  const _component_el_aside = _resolveComponent("el-aside")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, null, {
          default: _withCtx(() => [
            _createTextVNode("Header")
          ]),
          _: 1
        }),
        _createVNode(_component_el_main, null, {
          default: _withCtx(() => [
            _createTextVNode("Main")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, null, {
          default: _withCtx(() => [
            _createTextVNode("Header")
          ]),
          _: 1
        }),
        _createVNode(_component_el_main, null, {
          default: _withCtx(() => [
            _createTextVNode("Main")
          ]),
          _: 1
        }),
        _createVNode(_component_el_footer, null, {
          default: _withCtx(() => [
            _createTextVNode("Footer")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_aside, { width: "200px" }, {
          default: _withCtx(() => [
            _createTextVNode("Aside")
          ]),
          _: 1
        }),
        _createVNode(_component_el_main, null, {
          default: _withCtx(() => [
            _createTextVNode("Main")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, null, {
          default: _withCtx(() => [
            _createTextVNode("Header")
          ]),
          _: 1
        }),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_aside, { width: "200px" }, {
              default: _withCtx(() => [
                _createTextVNode("Aside")
              ]),
              _: 1
            }),
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => [
                _createTextVNode("Main")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, null, {
          default: _withCtx(() => [
            _createTextVNode("Header")
          ]),
          _: 1
        }),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_aside, { width: "200px" }, {
              default: _withCtx(() => [
                _createTextVNode("Aside")
              ]),
              _: 1
            }),
            _createVNode(_component_el_container, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_main, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Main")
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_footer, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Footer")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_aside, { width: "200px" }, {
          default: _withCtx(() => [
            _createTextVNode("Aside")
          ]),
          _: 1
        }),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, null, {
              default: _withCtx(() => [
                _createTextVNode("Header")
              ]),
              _: 1
            }),
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => [
                _createTextVNode("Main")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_aside, { width: "200px" }, {
          default: _withCtx(() => [
            _createTextVNode("Aside")
          ]),
          _: 1
        }),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, null, {
              default: _withCtx(() => [
                _createTextVNode("Header")
              ]),
              _: 1
            }),
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => [
                _createTextVNode("Main")
              ]),
              _: 1
            }),
            _createVNode(_component_el_footer, null, {
              default: _withCtx(() => [
                _createTextVNode("Footer")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createVNode: _createVNode, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")
  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group")
  const _component_el_submenu = _resolveComponent("el-submenu")
  const _component_el_menu = _resolveComponent("el-menu")
  const _component_el_aside = _resolveComponent("el-aside")
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")
  const _component_el_header = _resolveComponent("el-header")
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_table = _resolveComponent("el-table")
  const _component_el_main = _resolveComponent("el-main")
  const _component_el_container = _resolveComponent("el-container")

  return (_openBlock(), _createBlock(_component_el_container, { style: {"height":"500px","border":"1px solid #eee"} }, {
    default: _withCtx(() => [
      _createVNode(_component_el_aside, {
        width: "250px",
        style: {"background-color":"rgb(238, 241, 246)"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_menu, { "default-openeds": ['1', '3'] }, {
            default: _withCtx(() => [
              _createVNode(_component_el_submenu, { index: "1" }, {
                title: _withCtx(() => [
                  _createVNode("i", { class: "el-icon-message" }),
                  _createTextVNode("导航一")
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_menu_item_group, null, {
                    title: _withCtx(() => [
                      _createTextVNode("分组一")
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_el_menu_item, { index: "1-1" }, {
                        default: _withCtx(() => [
                          _createTextVNode("选项1")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_menu_item, { index: "1-2" }, {
                        default: _withCtx(() => [
                          _createTextVNode("选项2")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item_group, { title: "分组2" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_menu_item, { index: "1-3" }, {
                        default: _withCtx(() => [
                          _createTextVNode("选项3")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_submenu, { index: "1-4" }, {
                    title: _withCtx(() => [
                      _createTextVNode("选项4")
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_el_menu_item, { index: "1-4-1" }, {
                        default: _withCtx(() => [
                          _createTextVNode("选项4-1")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_submenu, { index: "2" }, {
                title: _withCtx(() => [
                  _createVNode("i", { class: "el-icon-menu" }),
                  _createTextVNode("导航二")
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_menu_item_group, null, {
                    title: _withCtx(() => [
                      _createTextVNode("分组一")
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_el_menu_item, { index: "2-1" }, {
                        default: _withCtx(() => [
                          _createTextVNode("选项1")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_menu_item, { index: "2-2" }, {
                        default: _withCtx(() => [
                          _createTextVNode("选项2")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item_group, { title: "分组2" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_menu_item, { index: "2-3" }, {
                        default: _withCtx(() => [
                          _createTextVNode("选项3")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_submenu, { index: "2-4" }, {
                    title: _withCtx(() => [
                      _createTextVNode("选项4")
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_el_menu_item, { index: "2-4-1" }, {
                        default: _withCtx(() => [
                          _createTextVNode("选项4-1")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_submenu, { index: "3" }, {
                title: _withCtx(() => [
                  _createVNode("i", { class: "el-icon-setting" }),
                  _createTextVNode("导航三")
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_menu_item_group, null, {
                    title: _withCtx(() => [
                      _createTextVNode("分组一")
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_el_menu_item, { index: "3-1" }, {
                        default: _withCtx(() => [
                          _createTextVNode("选项1")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_menu_item, { index: "3-2" }, {
                        default: _withCtx(() => [
                          _createTextVNode("选项2")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item_group, { title: "分组2" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_menu_item, { index: "3-3" }, {
                        default: _withCtx(() => [
                          _createTextVNode("选项3")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_submenu, { index: "3-4" }, {
                    title: _withCtx(() => [
                      _createTextVNode("选项4")
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_el_menu_item, { index: "3-4-1" }, {
                        default: _withCtx(() => [
                          _createTextVNode("选项4-1")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_header, { style: {"text-align":"right","font-size":"12px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_el_dropdown, null, {
                dropdown: _withCtx(() => [
                  _createVNode(_component_el_dropdown_menu, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_dropdown_item, null, {
                        default: _withCtx(() => [
                          _createTextVNode("查看")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_dropdown_item, null, {
                        default: _withCtx(() => [
                          _createTextVNode("新增")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_dropdown_item, null, {
                        default: _withCtx(() => [
                          _createTextVNode("删除")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode("i", {
                    class: "el-icon-setting",
                    style: {"margin-right":"15px"}
                  })
                ]),
                _: 1
              }),
              _createVNode("span", null, "王小虎")
            ]),
            _: 1
          }),
          _createVNode(_component_el_main, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_table, { data: _ctx.tableData }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    prop: "date",
                    label: "日期",
                    width: "140"
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "name",
                    label: "姓名",
                    width: "120"
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "address",
                    label: "地址"
                  })
                ]),
                _: 1
              }, 8, ["data"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {
    data() {
      const item = {
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }
      return {
        tableData: Array(20).fill(item)
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  